import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { QuestionCircleOutlined, ExclamationCircleOutlined, ProfileOutlined, PartitionOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectPerformanceRule, selectPerformanceById, financeConfirmPerformance, selectMyPerformanceByPagination, updatePerformance, insertPerformance, selectHistoryPerformanceInfo } from '@/api/personnel';
import { fa } from 'element-plus/es/locale'; //表头

const columns = [{
  title: '主题',
  dataIndex: 'theme'
}, {
  title: '发起人',
  dataIndex: 'applicantName'
}, {
  title: '工号',
  dataIndex: 'workNo'
}, {
  title: '所属部门',
  dataIndex: 'departmentName'
}, {
  title: '发起时间',
  dataIndex: 'createTime'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const kaoDetailColumns = [{
  title: '主题',
  dataIndex: 'theme',
  slots: {
    customRender: 'theme'
  }
}, {
  title: '内容描述',
  dataIndex: 'contentDescribed',
  slots: {
    customRender: 'contentDescribed'
  }
}, {
  title: '权重',
  dataIndex: 'weight',
  width: '110px',
  slots: {
    customRender: 'weight'
  }
}, {
  title: '得分',
  dataIndex: 'score',
  width: '110px',
  slots: {
    customRender: 'score'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  width: 80,
  slots: {
    customRender: 'operation'
  }
}];
const historyColumns = [{
  title: '主题',
  dataIndex: 'theme',
  slots: {
    customRender: 'theme'
  }
}, {
  title: '内容描述',
  dataIndex: 'contentDescribed',
  slots: {
    customRender: 'contentDescribed'
  }
}, {
  title: '权重',
  dataIndex: 'weight',
  width: '110px',
  slots: {
    customRender: 'weight'
  }
}, {
  title: '得分',
  dataIndex: 'score',
  width: '110px',
  slots: {
    customRender: 'score'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    uploadImgs,
    ProfileOutlined,
    PartitionOutlined,
    PaperClipOutlined
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin); //表格

    const tableData = ref([{}]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10
      };
      loading.value = true;

      try {
        let res = await selectMyPerformanceByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const kaoInfo = reactive({
      isOpenPerformance: false,
      openTime: null,
      theme: null,
      isHaveParent: false,
      approvalDtos: [],
      performanceRule: null,
      fileUrl: [],
      id: null
    });

    const getJxData = async () => {
      let res = await selectPerformanceRule({
        uid: isCompanyAdmin.value == 2 || isCompanyAdmin.value == 3 ? Cookies.get('uid') : null
      });

      if (res.code === 200) {
        Object.assign(kaoInfo, res.data);
        kaoInfo.fileUrl = JSON.parse(res.data.fileUrl);
        kaoInfo.isOpenPerformance = res.data.isOpenPerformance ? true : false;
      } else {
        _message.error(res.message);
      }
    }; //绩效详情


    const kaoDetailVisible = ref(false);
    const kaoDetailState = reactive({
      id: null,
      applicantName: null,
      performanceDetails: [],
      hyApproachApprovals: [],
      content: null,
      state: 0,
      belongProject: null,
      remark: null,
      updateList: [],
      actualState: 99
    });
    const kaoDetailRef = ref();
    const isDisabledForCl = ref(true);
    const isShowFlow = ref(false);
    const kaoDetailTitle = ref("绩效考核");

    const toView = async (id, num) => {
      if (num === 99) {
        isDisabledForCl.value = false;
        isShowFlow.value = false;
        kaoDetailTitle.value = `发起绩效考核-${kaoInfo.theme}`;
        kaoDetailVisible.value = true;
        kaoDetailState.state = num;
        return;
      }

      let res = await selectPerformanceById({
        id
      });

      if (res.code === 200) {
        Object.assign(kaoDetailState, res.data);
        kaoDetailState.actualState = res.data.state;

        if (res.data.updateList && res.data.updateList.length) {
          historyTime.value = res.data.updateList[0].createTime;
        }

        kaoDetailState.state = num;

        if (num === 37) {
          kaoDetailTitle.value = `发起绩效考核-${kaoInfo.theme}`;
          isDisabledForCl.value = false;
        } else if (num === 2) {
          kaoDetailTitle.value = `修改绩效考核-${kaoInfo.theme}`;
          isDisabledForCl.value = false;
        } else {
          kaoDetailTitle.value = `绩效考核详情-${kaoInfo.theme}`;
          isDisabledForCl.value = true;
        }

        kaoDetailVisible.value = true;
      } else {
        _message.error(res.message);
      }
    };

    const handleAdd = () => {
      kaoDetailState.performanceDetails.push({
        theme: null,
        contentDescribed: null,
        weight: null,
        score: null,
        id: null
      });
    };

    const onDelete = index => {
      if (kaoDetailState.performanceDetails.length == 1) {
        _message.error('绩效考核明细不能为空！');

        return;
      }

      kaoDetailState.performanceDetails.splice(index, 1);
    }; //发起考核


    const setKaoDetailLoading = ref(false);

    const handleInsertKao = savaType => {
      kaoDetailRef.value.validate().then(async () => {
        if (!kaoDetailState.performanceDetails.length) {
          _message.error('绩效考核明细不能为空！');

          return;
        }

        setKaoDetailLoading.value = true;
        let param = {
          theme: kaoInfo.theme,
          remark: kaoDetailState.remark,
          performanceDetails: kaoDetailState.performanceDetails,
          savaType,
          id: kaoDetailState.id
        };

        try {
          let res = await insertPerformance(param);

          if (res.code === 200) {
            _message.success(res.message);

            kaoDetailRef.value.resetFields();
            kaoDetailState.performanceDetails = [];
            kaoDetailState.id = null;
            kaoDetailState.applicantName = null;
            kaoDetailState.hyApproachApprovals = [];
            kaoDetailState.content = null;
            kaoDetailState.state = 0;
            kaoDetailState.belongProject = null;
            kaoDetailState.remark = null;
            setKaoDetailLoading.value = false;
            kaoDetailVisible.value = false;
            getTableData();
          } else {
            _message.error(res.message);

            setKaoDetailLoading.value = false;
          }
        } catch (error) {
          setKaoDetailLoading.value = false;
        }
      });
    };

    const handleUpdateKao = savaType => {
      kaoDetailRef.value.validate().then(async () => {
        if (!kaoDetailState.performanceDetails.length) {
          _message.error('绩效考核明细不能为空！');

          return;
        }

        setKaoDetailLoading.value = true;
        let param = {
          id: kaoDetailState.id,
          theme: kaoInfo.theme,
          remark: kaoDetailState.remark,
          performanceDetails: kaoDetailState.performanceDetails,
          savaType
        };

        try {
          let res = await updatePerformance(param);

          if (res.code === 200) {
            _message.success(res.message);

            kaoDetailRef.value.resetFields();
            kaoDetailState.performanceDetails = [];
            kaoDetailState.id = null;
            kaoDetailState.applicantName = null;
            kaoDetailState.hyApproachApprovals = [];
            kaoDetailState.content = null;
            kaoDetailState.state = 0;
            kaoDetailState.belongProject = null;
            kaoDetailState.remark = null;
            setKaoDetailLoading.value = false;
            kaoDetailVisible.value = false;
            getTableData();
          } else {
            _message.error(res.message);

            setKaoDetailLoading.value = false;
          }
        } catch (error) {
          setKaoDetailLoading.value = false;
        }
      });
    };

    const handleCancelKaoDetail = () => {
      kaoDetailRef.value.resetFields();
      kaoDetailState.performanceDetails = [];
      kaoDetailState.id = null;
      kaoDetailState.applicantName = null;
      kaoDetailState.hyApproachApprovals = [];
      kaoDetailState.content = null;
      kaoDetailState.state = 0;
      kaoDetailState.belongProject = null;
      kaoDetailState.remark = null;
      kaoDetailVisible.value = false;
    }; //确认


    const handleOkKaoDetail = async () => {
      let res = await financeConfirmPerformance({
        applyId: kaoDetailState.applyId
      });

      if (res.code === 200) {
        _message.success(res.message);

        kaoDetailRef.value.resetFields();
        kaoDetailState.performanceDetails = [];
        kaoDetailState.id = null;
        kaoDetailState.applicantName = null;
        kaoDetailState.hyApproachApprovals = [];
        kaoDetailState.content = null;
        kaoDetailState.state = 0;
        kaoDetailState.belongProject = null;
        kaoDetailState.remark = null;
        setKaoDetailLoading.value = false;
        kaoDetailVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);

        setKaoDetailLoading.value = false;
      }
    }; //查看考核规则


    const kaoVisible = ref(false);

    const handleToViewKao = () => {
      kaoVisible.value = true;
    }; //总分


    const weightSum = computed(() => {
      let sum = 0;

      if (kaoDetailState.performanceDetails.length) {
        kaoDetailState.performanceDetails.forEach(item => {
          if (typeof item.weight === 'number') {
            sum += item.weight;
          }
        });
      }

      return sum;
    });
    const scoreSum = computed(() => {
      let sum = 0;

      if (kaoDetailState.performanceDetails.length) {
        kaoDetailState.performanceDetails.forEach(item => {
          if (typeof item.score === 'number') {
            sum += item.score;
          }
        });
      }

      return sum;
    }); //查看历史

    const historyVisible = ref(false);

    const handleHistory = () => {
      historyVisible.value = true;
      getHistoryData();
    };

    const historyData = reactive({
      tableData: [],
      weightSum: 0,
      scoreSum: 0
    });
    const historyTime = ref('');

    const handleChangeHistoryTime = () => {
      getHistoryData();
    };

    const getHistoryData = async () => {
      historyData.tableData = [];
      historyData.weightSum = 0;
      historyData.scoreSum = 0;
      let res = await selectHistoryPerformanceInfo({
        performanceId: kaoDetailState.id,
        createTime: historyTime.value
      });

      if (res.code === 200) {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            historyData.tableData.push(item);
            historyData.weightSum += item.weight;
            historyData.scoreSum += item.score;
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getTableData();
      getJxData();
    });
    return {
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      isCompanyAdmin,
      kaoDetailVisible,
      kaoDetailState,
      kaoDetailRef,
      kaoDetailColumns,
      isDisabledForCl,
      handleAdd,
      onDelete,
      handleCancelKaoDetail,
      handleInsertKao,
      setKaoDetailLoading,
      handleOkKaoDetail,
      handleToViewKao,
      kaoVisible,
      kaoInfo,
      handleUpdateKao,
      kaoDetailTitle,
      weightSum,
      scoreSum,
      handleHistory,
      historyVisible,
      historyData,
      historyTime,
      handleChangeHistoryTime,
      historyColumns
    };
  }

});